import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import HomepageTemplate from "gatsby-theme-carbon/src/templates/Homepage";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageDescription mdxType="PageDescription">
    </PageDescription>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Web Development" href="services/web-design" actionIcon="arrowRight" subTitle="Web App" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "702px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEFAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHK517ZjqlAgP/EABoQAAICAwAAAAAAAAAAAAAAAAERBBAAAzH/2gAIAQEAAQUCRVR0Y2wvD2//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAWEQADAAAAAAAAAAAAAAAAAAABESD/2gAIAQIBAT8BCj//xAAeEAEAAQIHAAAAAAAAAAAAAAABEQACAxASICFBYf/aAAgBAQAGPwKYYzRQdXdJZZhnslc7P//EAB4QAAEDBAMAAAAAAAAAAAAAAAEAEUEQITFRYYGR/9oACAEBAAE/IefjO1l0fKZzYAmpB9s5OBSTnac7NP/aAAwDAQACAAMAAAAQe8A8/8QAFxEAAwEAAAAAAAAAAAAAAAAAAREgQf/aAAgBAwEBPxAvI//EABcRAAMBAAAAAAAAAAAAAAAAAAERICH/2gAIAQIBAT8QBdj/xAAhEAEAAQIFBQAAAAAAAAAAAAABEQAhMVFhcZEQQYHh8f/aAAgBAQABPxCRlDIWhynOiXDldLLssFgHZ83pihlk8Nkt6oAQuZjCdNK1HNfQpVxV3r//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/fb7b2c612a2d960de1523b3e6759b57e/5f1c3/web_magnus_lighter.webp 288w", "/static/fb7b2c612a2d960de1523b3e6759b57e/c4eb6/web_magnus_lighter.webp 576w", "/static/fb7b2c612a2d960de1523b3e6759b57e/1c613/web_magnus_lighter.webp 702w"],
                "sizes": "(max-width: 702px) 100vw, 702px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/fb7b2c612a2d960de1523b3e6759b57e/58ca1/web_magnus_lighter.jpg 288w", "/static/fb7b2c612a2d960de1523b3e6759b57e/7fee0/web_magnus_lighter.jpg 576w", "/static/fb7b2c612a2d960de1523b3e6759b57e/ecb77/web_magnus_lighter.jpg 702w"],
                "sizes": "(max-width: 702px) 100vw, 702px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/fb7b2c612a2d960de1523b3e6759b57e/ecb77/web_magnus_lighter.jpg",
                "alt": "Web development",
                "title": "Web development",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
  </ImageCard>
  <ImageCard title="Product Rendering" titleColor="dark" aspectRatio="1:1" href="services/product-visualization" actionIcon="arrowRight" subTitleColor="dark" subTitle="Rendering and Visualization" hoverColor="dark" iconColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "702px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAIEAQMF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAe3KlgtNRLIWAT//xAAaEAABBQEAAAAAAAAAAAAAAAACAAEDEBET/9oACAEBAAEFAmW0QSrnIKBny//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABoQAAICAwAAAAAAAAAAAAAAAAABECERIFL/2gAIAQEABj8Cl4aOi60//8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBEP/aAAgBAQABPyFxAlvZY6aRC9rm+RBdlPCdeu+Q58Z//9oADAMBAAIAAwAAABAT8L//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAcEAEAAwACAwAAAAAAAAAAAAABABExECFBYaH/2gAIAQEAAT8QRO062BgDgfMaqiYkp9T0H4hAGS2I77glaEhpo4mAWf/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d392ffd74b32ce8dfc405066b27fa50e/5f1c3/turbine.webp 288w", "/static/d392ffd74b32ce8dfc405066b27fa50e/c4eb6/turbine.webp 576w", "/static/d392ffd74b32ce8dfc405066b27fa50e/1c613/turbine.webp 702w"],
                "sizes": "(max-width: 702px) 100vw, 702px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d392ffd74b32ce8dfc405066b27fa50e/58ca1/turbine.jpg 288w", "/static/d392ffd74b32ce8dfc405066b27fa50e/7fee0/turbine.jpg 576w", "/static/d392ffd74b32ce8dfc405066b27fa50e/ecb77/turbine.jpg 702w"],
                "sizes": "(max-width: 702px) 100vw, 702px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/d392ffd74b32ce8dfc405066b27fa50e/ecb77/turbine.jpg",
                "alt": "Product Rendering",
                "title": "Product Rendering",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Industrial Automation" subTitle="IoT and Control System" aspectRatio="1:2" href="services/automation-iot" actionIcon="arrowRight" titleColor="light" subTitleColor="light" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "702px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "200%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAoABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAECAwQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB8JMlQTo2OQGlwwB//8QAHRAAAgIBBQAAAAAAAAAAAAAAAQIAEAMREyAyQf/aAAgBAQABBQKxw8mMas3WI5Sbpr//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AV//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AV//xAAdEAABBAIDAAAAAAAAAAAAAAAQABExMgIgIZGh/9oACAEBAAY/AtINhDqvo4ZRj0P/xAAcEAEAAgMAAwAAAAAAAAAAAAABESEAEEExUXH/2gAIAQEAAT8h3agnPHGi3EhgPhqY4GC1StJSFvZi5Gh//9oADAMBAAIAAwAAABAwBgD4D//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/ECkP/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARIP/aAAgBAgEBPxDFK//EAB0QAQACAwEAAwAAAAAAAAAAAAERIQAQMUFhccH/2gAIAQEAAT8Q2GhXbwGSpHwxolkQXhNAQRGL853XAJLCwGFLYogg+j80EWVln5i5IInPuv/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/5864282e71742f93e982fe2c9557a007/5f1c3/iot.webp 288w", "/static/5864282e71742f93e982fe2c9557a007/c4eb6/iot.webp 576w", "/static/5864282e71742f93e982fe2c9557a007/1c613/iot.webp 702w"],
                "sizes": "(max-width: 702px) 100vw, 702px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/5864282e71742f93e982fe2c9557a007/58ca1/iot.jpg 288w", "/static/5864282e71742f93e982fe2c9557a007/7fee0/iot.jpg 576w", "/static/5864282e71742f93e982fe2c9557a007/ecb77/iot.jpg 702w"],
                "sizes": "(max-width: 702px) 100vw, 702px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/5864282e71742f93e982fe2c9557a007/ecb77/iot.jpg",
                "alt": "Industrial Automation",
                "title": "Industrial Automation",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Mobile Application" subTitle="Hybrid" aspectRatio="1:1" href="services/mobile" actionIcon="arrowRight" hoverColor="dark" titleColor="light" subTitleColor="light" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "702px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAQFAQID/8QAFQEBAQAAAAAAAAAAAAAAAAAAAwT/2gAMAwEAAhADEAAAAYeNpF03AOazJHrBWH//xAAcEAACAgIDAAAAAAAAAAAAAAAAAQQSAgMQNEH/2gAIAQEAAQUCbSLrh42HH2VPI/XP/8QAGBEAAgMAAAAAAAAAAAAAAAAAARACAyH/2gAIAQMBAT8BEcVa/8QAGREAAgMBAAAAAAAAAAAAAAAAAxABAhEx/9oACAECAQE/AbEyeoy//8QAHRAAAQIHAAAAAAAAAAAAAAAAEAABAhESIXGBkf/aAAgBAQAGPwK5wp0t0xbH/8QAGxABAAIDAQEAAAAAAAAAAAAAAQAREDFxYZH/2gAIAQEAAT8h2qdfmFRQPqaQlXgqKlORqC3h/9oADAMBAAIAAwAAABCP/wD/AP/EABcRAQEBAQAAAAAAAAAAAAAAAAEQEUH/2gAIAQMBAT8QNDk4n//EABoRAQACAwEAAAAAAAAAAAAAAAEAERAxccH/2gAIAQIBAT8QRShWNXvkJ//EAB4QAQACAgEFAAAAAAAAAAAAAAERMQAhEEFRcZHR/9oACAEBAAE/EIzRNawan3/OEcCSgU34waFoG0RPV4VGJNqO+I6ldZawoz//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/6812956a02da98bf9080348805e6d55c/5f1c3/mobile_magnus.webp 288w", "/static/6812956a02da98bf9080348805e6d55c/c4eb6/mobile_magnus.webp 576w", "/static/6812956a02da98bf9080348805e6d55c/1c613/mobile_magnus.webp 702w"],
                "sizes": "(max-width: 702px) 100vw, 702px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/6812956a02da98bf9080348805e6d55c/58ca1/mobile_magnus.jpg 288w", "/static/6812956a02da98bf9080348805e6d55c/7fee0/mobile_magnus.jpg 576w", "/static/6812956a02da98bf9080348805e6d55c/ecb77/mobile_magnus.jpg 702w"],
                "sizes": "(max-width: 702px) 100vw, 702px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/6812956a02da98bf9080348805e6d55c/ecb77/mobile_magnus.jpg",
                "alt": "Mobile Application",
                "title": "Mobile Application",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
  </ImageCard>
      <ImageCard title="Mechanical Design" subTitle="Product design" aspectRatio="1:1" actionIcon="arrowRight" href="services/mechanical-design" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "702px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHA12q+etEpC1yAP//EABsQAAIDAAMAAAAAAAAAAAAAAAECAAMTEiEi/9oACAEBAAEFAsTMZgYzMFR+UVrIV81dNoQf/8QAGhEAAgIDAAAAAAAAAAAAAAAAAAIBEQMQMf/aAAgBAwEBPwFUuCjFzX//xAAWEQEBAQAAAAAAAAAAAAAAAAABEAL/2gAIAQIBAT8BWan/xAAbEAACAgMBAAAAAAAAAAAAAAAAIRAxARJRgf/aAAgBAQAGPwKhqEMo25g9j//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFBYTH/2gAIAQEAAT8hOlGeEPHZm9+uwigNJSaTv1hL1dEFntIqBX3pP//aAAwDAQACAAMAAAAQY/8AfP/EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QRkbd1psL/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBEDH/2gAIAQIBAT8QRKGar//EAB0QAQACAwEAAwAAAAAAAAAAAAEAESExQVGRodH/2gAIAQEAAT8QqU68mHY6XsA4A9SBFbZKKHT8j95Ym+WYgabVly/cv0HCnHxCYKuwxN6j0rv1n//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/2a0a8c56e61826cc7e45638fdb5e628a/5f1c3/design.webp 288w", "/static/2a0a8c56e61826cc7e45638fdb5e628a/c4eb6/design.webp 576w", "/static/2a0a8c56e61826cc7e45638fdb5e628a/1c613/design.webp 702w"],
                "sizes": "(max-width: 702px) 100vw, 702px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/2a0a8c56e61826cc7e45638fdb5e628a/58ca1/design.jpg 288w", "/static/2a0a8c56e61826cc7e45638fdb5e628a/7fee0/design.jpg 576w", "/static/2a0a8c56e61826cc7e45638fdb5e628a/ecb77/design.jpg 702w"],
                "sizes": "(max-width: 702px) 100vw, 702px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/2a0a8c56e61826cc7e45638fdb5e628a/ecb77/design.jpg",
                "alt": "Mechanical Design",
                "title": "Mechanical Design",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      